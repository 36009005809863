import './Consultas.css';
import { Helmet } from 'react-helmet';

import wpp from "../../../assets/img-main/wpplogo.webp"
import homem_celular from "../../../assets/img-main/homem-com-celular.webp"

import usePageTracking from '../../../hooks/usePageTracking';

const Consultas = () => {

    usePageTracking();

    return (
        <section className="consultas no-copy">
            <Helmet>
                <link rel="canonical" href="https://www.gocarelitoral.com.br/consultas" />
                <title>Agendamento de Consultas | GoCare Saúde Litoral</title>
                <meta name="description"
                    content="Facilite o agendamento das suas consultas. Ligue para a nossa central e deixe que a gente marque sua consulta com os melhores profissionais da nossa rede credenciada." />
            </Helmet>

            <div className='consultas__container'>

                <div className='consultas__imagem2'>
                    <img 
                        src={homem_celular}
                        alt='Homem segurando celular' />
                </div>

                <div className='consultas__titulo__container'>

                    <div className='consultas__titulo'>

                        <h2>
                            AGENDAMOS SUAS CONSULTAS
                        </h2>
                        <div className="div-separate">
                            <span className="style-div-separate"></span>
                        </div>
                    </div>

                    <div className='consultas__descricao'>

                        <p>
                            Envie o <strong>nome completo</strong> do <strong>beneficiário</strong>, <strong>data de nascimento</strong>, <strong>telefone de contato</strong>, e a <strong>especialidade</strong> ou <strong>exame necessário</strong>, e nós cuidaremos do agendamento para você.
                        </p>

                    </div>

                </div>

                <div className='consultas__contato__container'>

                    <div className='consultas__contato'>

                        <div className="div-separate">
                            <span className="style-div-separate"></span>
                        </div>

                        <div className='consultas__contato__numero'>

                            <img 
                                src={wpp}
                                alt='ícone do WhatsApp'
                            />

                            <h3 className='no-copy'>
                                (19) 2102-9733
                            </h3>

                        </div>

                        <p>
                            <strong>AGENDE</strong> AGORA MESMO
                        </p>

                    </div>


                    <div className='consultas__imagem'>
                        <img 
                            src={homem_celular}
                            alt='Homem segurando celular' />
                    </div>

                </div>

            </div>

        </section >
    )
}

export default Consultas;