

import "./Abrangencia.css";
import cityicon from "../../../../assets/img-main/cityicon.webp"

const Abrangencia = () => {
    return (
        <div className="abrangencia__container">

            <div className="abrangencia__titulo">
                <h2>
                    ÁREA DE ABRANGÊNCIA
                </h2>
            </div>

            <div className="cidades">
                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Bertioga
                    </p>
                </div>

                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Cubatão
                    </p>
                </div>

                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Guarujá
                    </p>
                </div>

                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Itanhaém
                    </p>
                </div>

                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Mongaguá
                    </p>
                </div>

                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Peruíbe
                    </p>
                </div>

                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Praia Grande
                    </p>
                </div>

                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        Santos
                    </p>
                </div>

                <div className="cidades__item">
                    <img 
                        src={cityicon}
                        alt="Ícone de localização de uma cidade"
                    />
                    <p>
                        São Vicente
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Abrangencia;