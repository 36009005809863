import React from 'react';
import './CopModal.css';

const CopModal = ({ showModal, closeModal }) => {
  if (!showModal) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={closeModal}>X</button>
        <h2>Tabela de Coparticipação</h2>
        <p>Abaixo estão os valores de coparticipação com os valores máximos de terapias, procedimentos e materiais importados.</p>
        <table>
          <thead>
            <tr>
              <th>Fator Moderador</th>
              <th>Coparticipação Financeira</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>Fisioterapia</td>
              <td>R$ 70,00</td>
            </tr> */}
            <tr>
              <td>Fonoaudiologia</td>
              <td>R$ 120,00</td>
            </tr>
            <tr>
              <td>Nutrição</td>
              <td>R$ 70,00</td>
            </tr>
            <tr>
              <td>Psicologia</td>
              <td>R$ 120,00</td>
            </tr>
            <tr>
              <td>Terapia Ocupacional</td>
              <td>R$ 120,00</td>
            </tr>
            <tr>
              <td>Método ABA</td>
              <td>R$ 180,00</td>
            </tr>
            <tr>
              <td>Terapia Denver</td>
              <td>R$ 125,00</td>
            </tr>
            <tr>
              <td>Avaliação Neuropsicológica</td>
              <td>R$ 140,00 <span>por sessão</span></td>
            </tr>
            <tr>
              <td>Psicopedagogia</td>
              <td>R$ 150,00</td>
            </tr>
            <tr>
              <td>Cirurgia Bariátrica</td>
              <td>R$ 9.000,00</td>
            </tr>
            <tr>
              <td>Abdominoplastia</td>
              <td>R$ 6.000,00</td>
            </tr>
            <tr>
              <td>Órteses, Próteses e Materiais Importados</td>
              <td>50% do valor do material ligado ao ato cirúrgico</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CopModal;
