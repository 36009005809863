import React, { useState, useEffect } from "react";
import "./ModalNovoHospital.css";

import novohospital from "../../../../assets/img-main/HospitalGoCare.webp"

const ModalNovoHospital = () => {
  const [hospitalModal, setHospitalModal] = useState(false);

  useEffect(() => {
    setHospitalModal(true);
  }, []);

  const fecharHospitalModal = () => {
    setHospitalModal(false);
  };

  return (
    <>
      {hospitalModal && (
        <div className="modal-hospital-overlay">
          <div className="modal-hospital-container">
            <button onClick={fecharHospitalModal} className="modal-hospital-close-button">
              &times;
            </button>
            <h2>Novo Local de Atendimento</h2>
            <p>Agora, na Baixada Santista, conte com o nosso <span>Hospital GoCare</span>, que integra a nossa rede exclusiva de atendimento!<br />
              Estamos localizados na <span>Rua Montenegro, 41<br /> Vila Maia, Guarujá - SP</span>. </p>
            <img
              src={novohospital}
              alt="Hospital GoCare"
              className="modal-hospital-image"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ModalNovoHospital;
