const RedesCubatao = [
    {
        especialidade: 'Anatomia Patológica',
        estabelecimentos: [
            {
                nome: 'Laboratório Clínico Santa Clara',
                endereco: 'Rua Maria do Carmo, 240, Jardim Casqueiro, Cubatão / SP - CEP 11533-050',
                telefones: ['(13) 3363-2573', '(13) 99183-0475'],
            },
        ],
    },
    {
        especialidade: 'Cardiologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101'],
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
        ],
    },
    {
        especialidade: 'Cirurgia Geral Odontológica',
        estabelecimentos: [
            {
                nome: 'José Ribeiro de Freitas Neto',
                endereco: 'Rua Pedro de Toledo, 103, Vila Paulista, Cubatão / SP - CEP 11510-090',
                telefones: ['(13) 3361-4625'],
            },
            {
                nome: 'Jéssica Andrade de Sa',
                endereco: 'Avenida Henry Borden, 632, Vila Santa Rosa, Cubatão / SP - CEP 11515-000',
                telefones: ['(13) 98865-9296', '(13) 99658-6562'],
            },
            {
                nome: 'Sarah da Nobrega Teixeira',
                endereco: 'Avenida Nove de Abril, 2166, Centro, Cubatão / SP - CEP 11510-001',
                telefones: ['(13) 3329-0122', '(13) 98865-5449'],
            },
            {
                nome: 'Sou Orto Saúde Odontológica Unifcada',
                endereco: 'Rua Pedro de Toledo, 443, Vila Paulista, Cubatão / SP - CEP 11510-090',
                telefones: ['(13) 3304-0610', '(13) 3361-2365'],
            },
        ],
    },
    {
        especialidade: 'Cirurgia Vascular',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101'],
            },
        ],
    },
    {
        especialidade: 'Citopatologia',
        estabelecimentos: [
            {
                nome: 'Laboratório Clínico Santa Clara',
                endereco: 'Rua Maria do Carmo, 240, Jardim Casqueiro, Cubatão / SP - CEP 11533-050',
                telefones: ['(13) 3363-2573', '(13) 99183-0475'],
            },
        ],
    },
    {
        especialidade: 'Clínica Geral Odontológica',
        estabelecimentos: [
            {
                nome: 'C&D Centro Odontológico',
                endereco: 'Avenida Martins Fontes, 356, Vila Nova, Cubatão / SP - CEP 11520-110',
                telefones: ['(13) 3371-9461', '(13) 99799-5651'],
            },
            {
                nome: 'Clinica Odontológica Sorria Perfeito',
                endereco: 'Avenida Martins Fontes, 139, Vila Nova, Cubatão / SP - CEP 11520-110',
                telefones: ['(13) 98219-3745'],
            },
            {
                nome: 'José Ribeiro de Freitas Neto',
                endereco: 'Rua Pedro de Toledo, 103, Vila Paulista, Cubatão / SP - CEP 11510-090',
                telefones: ['(13) 3361-4625'],
            },
            {
                nome: 'Jéssica Andrade de Sa',
                endereco: 'Avenida Henry Borden, 632, Vila Santa Rosa, Cubatão / SP - CEP 11515-000',
                telefones: ['(13) 98865-9296', '(13) 99658-6562'],
            },
            {
                nome: 'Sarah da Nobrega Teixeira',
                endereco: 'Avenida Nove de Abril, 2166, Centro, Cubatão / SP - CEP 11510-001',
                telefones: ['(13) 3329-0122', '(13) 98865-5449'],
            },
            {
                nome: 'Sou Orto Saúde Odontológica Unifcada',
                endereco: 'Rua Pedro de Toledo, 443, Vila Paulista, Cubatão / SP - CEP 11510-090',
                telefones: ['(13) 3304-0610', '(13) 3361-2365'],
            },
        ],
    },
    {
        especialidade: 'Clínica Médica',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101'],
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
            {
                nome: 'Clínica Médica Somaprev',
                endereco: 'Rua São Paulo, 375, Jardim São Francisco, Cubatão / SP - CEP 11500-020',
                telefones: ['(13) 3016-0758', '(13) 99757-7548'],
            },
            {
                nome: 'Saúde Essencial Clínica Médica',
                endereco: 'Rua Maria Graziela, 986, Jardim Casqueiro, Cubatão / SP - CEP 11530-070',
                telefones: ['(13) 3371-8500', '(13) 99727-0000'],
            },
        ],
    },
    {
        especialidade: 'Coloproctologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101'],
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
        ],
    },
    {
        especialidade: 'Dentística',
        estabelecimentos: [
            {
                nome: 'C&D Centro Odontológico',
                endereco: 'Avenida Martins Fontes, 356, Vila Nova, Cubatão / SP - CEP 11520-110',
                telefones: ['(13) 3371-9461', '(13) 99799-5651'],
            },
            {
                nome: 'Clinica Odontológica Sorria Perfeito',
                endereco: 'Avenida Martins Fontes, 139, Vila Nova, Cubatão / SP - CEP 11520-110',
                telefones: ['(13) 98219-3745'],
            },
            {
                nome: 'Croma Centro de Radiologia Odontológica - Afonso Pena',
                endereco: 'Avenida Nove de Abril, 2068, Centro, Cubatão / SP - CEP 11510-000',
                telefones: ['(13) 3223-3490'],
            },
            {
                nome: 'José Ribeiro de Freitas Neto',
                endereco: 'Rua Pedro de Toledo, 103, Vila Paulista, Cubatão / SP - CEP 11510-090',
                telefones: ['(13) 3361-4625'],
            },
            {
                nome: 'Jéssica Andrade de Sa',
                endereco: 'Avenida Henry Borden, 632, Vila Santa Rosa, Cubatão / SP - CEP 11515-000',
                telefones: ['(13) 98865-9296', '(13) 99658-6562'],
            },
            {
                nome: 'Sou Orto Saúde Odontológica Unifcada',
                endereco: 'Rua Pedro de Toledo, 443, Vila Paulista, Cubatão / SP - CEP 11510-090',
                telefones: ['(13) 3304-0610', '(13) 3361-2365'],
            },
        ],
    },
    {
        especialidade: 'Dermatologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101'],
            },
        ],
    },
    {
        especialidade: 'Endocrinologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101'],
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
        ],
    },
    {
        especialidade: 'Endodontia',
        estabelecimentos: [
            {
                nome: 'Jéssica Andrade de Sa',
                endereco: 'Avenida Henry Borden, 632, Vila Santa Rosa, Cubatão / SP - CEP 11515-000',
                telefones: ['(13) 98865-9296', '(13) 99658-6562'],
            },
        ],
    },
    {
        especialidade: 'Fisioterapia',
        estabelecimentos: [
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
        ],
    },
    {
        especialidade: 'Gastroenterologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101'],
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
            {
                nome: 'Clínica Médica Somaprev',
                endereco: 'Rua São Paulo, 375, Jardim São Francisco, Cubatão / SP - CEP 11500-020',
                telefones: ['(13) 3016-0758', '(13) 99757-7548'],
            },
        ],
    },
    {
        especialidade: 'Ginecologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101'],
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
            {
                nome: 'Clínica Médica Somaprev',
                endereco: 'Rua São Paulo, 375, Jardim São Francisco, Cubatão / SP - CEP 11500-020',
                telefones: ['(13) 3016-0758', '(13) 99757-7548'],
            },
        ],
    },
    {
        especialidade: 'Medicina Laboratorial',
        estabelecimentos: [
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
            {
                nome: 'Laboratório Clínico Santa Clara',
                endereco: 'Rua Maria do Carmo, 240, Jardim Casqueiro, Cubatão / SP - CEP 11533-050',
                telefones: ['(13) 3363-2573', '(13) 99183-0475'],
            },
        ],
    },
    {
        especialidade: 'Nutrição',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
            {
                nome: 'Clínica Médica Somaprev',
                endereco: 'Rua São Paulo, 375, Jardim São Francisco, Cubatão / SP - CEP 11500-020',
                telefones: ['(13) 3016-0758', '(13) 99757-7548'],
            },
        ]
    },
    {
        especialidade: 'Odontopediatria',
        estabelecimentos: [
            {
                nome: 'Jéssica Andrade de Sa',
                endereco: 'Avenida Henry Borden, 632, Vila Santa Rosa, Cubatão / SP - CEP 11515-000',
                telefones: ['(13) 98865-9296', '(13) 99658-6562']
            }
        ]
    },
    {
        especialidade: 'Oftalmologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
            {
                nome: 'Clínica Médica Somaprev',
                endereco: 'Rua São Paulo, 375, Jardim São Francisco, Cubatão / SP - CEP 11500-020',
                telefones: ['(13) 3016-0758', '(13) 99757-7548'],
            },
        ]
    },
    {
        especialidade: 'Ortodontia',
        estabelecimentos: [
            {
                nome: 'C&D Centro Odontológico',
                endereco: 'Avenida Martins Fontes, 356, Vila Nova, Cubatão / SP - CEP 11520-110',
                telefones: ['(13) 3371-9461', '(13) 99799-5651']
            },
            {
                nome: 'Sarah da Nobrega Teixeira',
                endereco: 'Avenida Nove de Abril, 2166, Centro, Cubatão / SP - CEP 11510-001',
                telefones: ['(13) 3329-0122', '(13) 98865-5449']
            },
            {
                nome: 'Sou Orto Saúde Odontológica Unifcada',
                endereco: 'Rua Pedro de Toledo, 443, Vila Paulista, Cubatão / SP - CEP 11510-090',
                telefones: ['(13) 3304-0610', '(13) 3361-2365']
            }
        ]
    },
    {
        especialidade: 'Ortopedia e Traumatologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            },
            {
                nome: 'Clínica Médica Somaprev',
                endereco: 'Rua São Paulo, 375, Jardim São Francisco, Cubatão / SP - CEP 11500-020',
                telefones: ['(13) 3016-0758', '(13) 99757-7548'],
            },
        ]
    },
    {
        especialidade: 'Ortopedia Geral',
        estabelecimentos: [
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
        ]
    },
    {
        especialidade: 'Otorrinolaringologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            },
        ]
    },
    {
        especialidade: 'Pediatria',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
            {
                nome: 'Clínica Médica Somaprev',
                endereco: 'Rua São Paulo, 375, Jardim São Francisco, Cubatão / SP - CEP 11500-020',
                telefones: ['(13) 3016-0758', '(13) 99757-7548'],
            },
        ]
    },
    {
        especialidade: 'Periodontia',
        estabelecimentos: [
            {
                nome: 'Sarah da Nobrega Teixeira',
                endereco: 'Avenida Nove de Abril, 2166, Centro, Cubatão / SP - CEP 11510-001',
                telefones: ['(13) 3329-0122', '(13) 98865-5449']
            }
        ]
    },
    {
        especialidade: 'Psicologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
        ]
    },
    {
        especialidade: 'Psiquiatria',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
        ]
    },
    {
        especialidade: 'Reumatologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            },
            {
                nome: 'Clinica Flex',
                endereco: 'Avenida Joaquim Miguel Couto, 200, Jardim São Francisco, Cubatão / SP - CEP 11500-005',
                telefones: ['(13) 3375-9111', '(13) 99714-3771'],
            },
        ]
    },
    {
        especialidade: 'Urologia',
        estabelecimentos: [
            {
                nome: 'Centro Médico de Cubatão',
                endereco: 'Rua Antônio Lemos, 27, Vila Paulista, Cubatão / SP - CEP 11510-110',
                telefones: ['0800 521-0101']
            }
        ]
    }
];

export default RedesCubatao;
