import { Helmet } from 'react-helmet';

import './About.css';

import AboutInfo from './aboutinfo/AboutInfo.js';
import Abrangencia from './abrangencia/Abrangencia.js';
import Mission from './mission/Mission.js';
import Cobertura from './cobertura/Cobertura.js';
import usePageTracking from '../../../hooks/usePageTracking.js';

import family from '../../../assets/img-main/family.webp';

const About = () => {

    usePageTracking();

    return (
        <div className="about__container">

            <Helmet>
                <link rel="canonical" href="https://www.gocarelitoral.com.br/sobre" />
                <title>Sobre Nós | GoCare Saúde Litoral</title>
                <meta name="description"
                    content="Conheça a GoCare Saúde no Litoral! Oferecemos planos de saúde acessíveis e completos, pensados para o bem-estar de você e sua família na Baixada Santista. Descubra nossa missão de cuidar do que importa: sua saúde." />
            </Helmet>

            <section className='about__banner-container'>

                <div className='about__banner'>
                    <img 
                        className='banner__img'
                        src={family}
                        alt="Imagem de uma família"
                    />
                </div>

                <AboutInfo />
            </section>

            <section className="additional__info">
                <Mission />
            </section>

            <section className="abrangencia">
                <Abrangencia />
            </section>

            <section className="cobertura">
                <Cobertura />
            </section>
        </div>

    )
}

export default About;