const RedesSaoVicente = [
    {
        especialidade: "Anatomia Patológica",
        estabelecimentos: [
            {
                nome: "Labi Exames - São Vicente",
                endereco: "Rua João Ramalho, 574, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(11) 3003-2686"],
            },
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Rua Padre Anchieta, 224 Centro, São Vicente / SP - CEP 11310-040",
                telefones: ["(13) 3221-8124', '(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga (Centro)",
                endereco: "Rua Frei Gaspar, 637, Centro, São Vicente / SP - CEP 11310-061",
                telefones: ["(13) 3466-2586"],
            },
            {
                nome: "Laboratório Gonzaga (Jardim Rio Branco)",
                endereco: "Avenida Deputado Ulisses Guimarães, 1662, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["(13) 3566-2425"],
            },
        ],
    },
    {
        especialidade: "Cardiologia",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Ipiranga LTDA São Vicente",
                endereco: "Rua Gonçalo Monteiro, 154, Centro, São Vicente / SP - CEP 11320-110",
                telefones: ["(13) 3468-9912"],
            },
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral",
        estabelecimentos: [
            {
                nome: "Clínica Médica São José",
                endereco: "Rua Tibiriçá, 439, Centro, São Vicente / SP - CEP 11320-020",
                telefones: ["(13) 3569-0200"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Cesar Alves Malerba",
                endereco: "Rua Benjamin Constant, 61, Centro, São Vicente / SP - CEP 11310-500",
                telefones: ["(13) 3561-1295", "(13) 99712-2734"],
            },
            {
                nome: "Lourdes Regina Salgueiro Castro",
                endereco: "Avenida Marechal Deodoro, 737, Vila Valença, São Vicente / SP - CEP 11390-100",
                telefones: ["(13) 99719-2984"],
            },
            {
                nome: "Sorria Sim Clínicas",
                endereco: "Rua João Ramalho, 821, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3324-3188"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Vascular",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - São Vicente",
                endereco: "Avenida Presidente Wilson, 199, Itararé, São Vicente / SP - CEP 11320-001",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Clínica Ipiranga LTDA São Vicente",
                endereco: "Rua Gonçalo Monteiro, 154, Centro, São Vicente / SP - CEP 11320-110",
                telefones: ["(13) 3468-9912"],
            },
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ],
    },
    {
        especialidade: "Citopatologia",
        estabelecimentos: [
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Rua Padre Anchieta, 224 Centro, São Vicente / SP - CEP 11310-040",
                telefones: ["(13) 3221-8124', '(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga (Centro)",
                endereco: "Rua Frei Gaspar, 637, Centro, São Vicente / SP - CEP 11310-061",
                telefones: ["(13) 3466-2586"],
            },
            {
                nome: "Laboratório Gonzaga (Jardim Rio Branco)",
                endereco: "Avenida Deputado Ulisses Guimarães, 1662, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["(13) 3566-2425"],
            },
        ],
    },
    {
        especialidade: "Clínica Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Cintia Mara Aoki Tronoloni",
                endereco: "Rua Frei Gaspar, 931, Centro, São Vicente / SP - CEP 11310-061",
                telefones: ["(13) 99743-1234"],
            },
            {
                nome: "COP - Centro Odontológico Personalizado",
                endereco: "Rua Quinze de Novembro, 304, Centro, São Vicente / SP - CEP 11310-400",
                telefones: ["(13) 3568-8182"],
            },
            {
                nome: "Dentiste Odontologia",
                endereco: "Rua Amador Bueno da Ribeira, 64, Centro, São Vicente / SP - CEP 11320-060",
                telefones: ["(13) 98166-0058"],
            },
            {
                nome: "Gabriela Silva Angelin Cavalcante",
                endereco: "Rua João Ramalho, 626, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 99161-0400"],
            },
            {
                nome: "Lourdes Regina Salgueiro Castro",
                endereco: "Avenida Marechal Deodoro, 737, Vila Valença, São Vicente / SP - CEP 11390-100",
                telefones: ["(13) 99719-2984"],
            },
            {
                nome: "Marcela Fernanda Bianco Mastros",
                endereco: "Rua Dom Lara, 850, Vila Valença, São Vicente / SP - CEP 11390-140",
                telefones: ["(13) 3329-8355", "(13) 99114-2596"],
            },
            {
                nome: "Mylena Vieira Odontologia",
                endereco: "Rua Benjamin Constant, 61, Centro, São Vicente / SP - CEP 11310-500",
                telefones: ["(13) 97420-4927"],
            },
            {
                nome: "NF Odontologia a Arte de Sorrir",
                endereco: "Rua Frei Gaspar, 910, Centro, São Vicente / SP - CEP 11310-061",
                telefones: ["(13) 3468-8000"],
            },
            {
                nome: "Oral Martins",
                endereco: "Avenida Antônio Emmerick, 1508, Vila São Jorge, São Vicente / SP - CEP 11370-000",
                telefones: ["(13) 3561-9044", "(13) 98196-6678"],
            },
            {
                nome: "Ortho & Implants",
                endereco: "Rua Padre Anchieta, 306, Centro, São Vicente / SP - CEP 11310-040",
                telefones: ["(13) 3467-8896"],
            },
            {
                nome: "Sorria Sim Clínicas",
                endereco: "Rua João Ramalho, 821, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3324-3188"],
            },
            {
                nome: "Sorrir Mais Clínica Odontológica São Vicente",
                endereco: "Rua João Ramalho, 803, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3467-8111", "(13) 3468-6988", "(11) 99112-1315"],
            },
            {
                nome: "Thaile Cristina Rodrigues Gomes",
                endereco: "Avenida Antônio Emmerick, 614, Vila Cascatinha, São Vicente / SP - CEP 11390-001",
                telefones: ["(13) 99614-8886"],
            },
            {
                nome: "Uniero",
                endereco: "Rua Berta Craveiro Lopes, 40, Jardim Independência, São Vicente / SP - CEP 11380-510",
                telefones: ["(13) 3324-3296", "(13) 9414-7573"],
            },
        ],
    },
    {
        especialidade: "Clínica Médica",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - São Vicente",
                endereco: "Avenida Presidente Wilson, 199, Itararé, São Vicente / SP - CEP 11320-001",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Médica São José",
                endereco: "Rua Tibiriçá, 439, Centro, São Vicente / SP - CEP 11320-020",
                telefones: ["(13) 3569-0200"],
            },
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ],
    },
    {
        especialidade: "Dentística",
        estabelecimentos: [
            {
                nome: "Cintia Mara Aoki Tronoloni",
                endereco: "Rua Frei Gaspar, 931, Centro, São Vicente / SP - CEP 11310-061",
                telefones: ["(13) 99743-1234"],
            },
            {
                nome: "Croma Centro de Radiologia Odontológica - Afonso Pena",
                endereco: "Rua João Ramalho, 803, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3223-3490"],
            },
            {
                nome: "Dentiste Odontologia",
                endereco: "Rua Amador Bueno da Ribeira, 64, Centro, São Vicente / SP - CEP 11320-060",
                telefones: ["(13) 98166-0058"],
            },
            {
                nome: "Gabriela Silva Angelin Cavalcante",
                endereco: "Rua João Ramalho, 626, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 99161-0400"],
            },
            {
                nome: "Lourdes Regina Salgueiro Castro",
                endereco: "Avenida Marechal Deodoro, 737, Vila Valença, São Vicente / SP - CEP 11390-100",
                telefones: ["(13) 99719-2984"],
            },
            {
                nome: "Marcela Fernanda Bianco Mastros",
                endereco: "Rua Dom Lara, 850, Vila Valença, São Vicente / SP - CEP 11390-140",
                telefones: ["(13) 3329-8355", "(13) 99114-2596"],
            },
            {
                nome: "Mylena Vieira Odontologia",
                endereco: "Rua Benjamin Constant, 61, Centro, São Vicente / SP - CEP 11310-500",
                telefones: ["(13) 97420-4927"],
            },
            {
                nome: "NF Odontologia a Arte de Sorrir",
                endereco: "Rua Frei Gaspar, 910, Centro, São Vicente / SP - CEP 11310-061",
                telefones: ["(13) 3468-8000"],
            },
            {
                nome: "Ortho & Implants",
                endereco: "Rua Padre Anchieta, 306, Centro, São Vicente / SP - CEP 11310-040",
                telefones: ["(13) 3467-8896"],
            },
            {
                nome: "Sorria Sim Clínicas",
                endereco: "Rua João Ramalho, 821, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3324-3188"],
            },
            {
                nome: "Thaile Cristina Rodrigues Gomes",
                endereco: "Avenida Antônio Emmerick, 614, Vila Cascatinha, São Vicente / SP - CEP 11390-001",
                telefones: ["(13) 99614-8886"],
            },
        ],
    },
    {
        especialidade: "Endocrinologia",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Médica São José",
                endereco: "Rua Tibiriçá, 439, Centro, São Vicente / SP - CEP 11320-020",
                telefones: ["(13) 3569-0200"],
            },
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ],
    },
    {
        especialidade: "Endodontia",
        estabelecimentos: [
            {
                nome: "Cesar Alves Malerba",
                endereco: "Rua Benjamin Constant, 61, Centro, São Vicente / SP - CEP 11310-500",
                telefones: ["(13) 3561-1295", "(13) 99712-2734"],
            },
            {
                nome: "Sorria Sim Clínicas",
                endereco: "Rua João Ramalho, 821, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3324-3188"],
            },
            {
                nome: "Sorrir Mais Clínica Odontológica São Vicente",
                endereco: "Rua João Ramalho, 803, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3467-8111", "(13) 3468-6988", "(11) 99112-1315"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia",
        estabelecimentos: [
            {
                nome: "Clínica de Fisioterapia Renascer Santa Isabel",
                endereco: "Avenida Capitão-Mor Aguiar, 445 Centro, São Vicente / SP - CEP 11310-200",
                telefones: ["(13) 3467-6133"],
            },
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia - Neurológica",
        estabelecimentos: [
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia - Ortopédica",
        estabelecimentos: [
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Gastroenterologia",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - São Vicente",
                endereco: "Avenida Presidente Wilson, 199, Itararé, São Vicente / SP - CEP 11320-001",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Clínica Médica São José",
                endereco: "Rua Tibiriçá, 439, Centro, São Vicente / SP - CEP 11320-020",
                telefones: ["(13) 3569-0200"],
            },
        ],
    },
    {
        especialidade: "Geriatria",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "Ginecologia",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Médica São José",
                endereco: "Rua Tibiriçá, 439, Centro, São Vicente / SP - CEP 11320-020",
                telefones: ["(13) 3569-0200"],
            },
        ],
    },
    {
        especialidade: "Ginecologia e Obstetrícia",
        estabelecimentos: [
            {
                nome: "Clínica de Ginecologia e Obstetrícia Carlos Vilar LTDA",
                endereco: "Avenida Presidente Wilson, 1473, Centro, São Vicente / SP - CEP 11320-915",
                telefones: ["(13) 3467-2310", "(13) 99197-3849"],
            },
        ],
    },
    {
        especialidade: "Hematologia e Hemoterapia",
        estabelecimentos: [
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ]
    },
    {
        especialidade: "Medicina Laboratorial",
        estabelecimentos: [
            {
                nome: "Labi Exames - São Vicente",
                endereco: "Rua João Ramalho, 574, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(11) 3003-2686"],
            },
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Rua Padre Anchieta, 224 Centro, São Vicente / SP - CEP 11310-040",
                telefones: ["(13) 3221-8124', '(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga (Centro)",
                endereco: "Rua Frei Gaspar, 637, Centro, São Vicente / SP - CEP 11310-061",
                telefones: ["(13) 3466-2586"],
            },
            {
                nome: "Laboratório Gonzaga (Jardim Rio Branco)",
                endereco: "Avenida Deputado Ulisses Guimarães, 1662, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["(13) 3566-2425"],
            },
        ],
    },
    {
        especialidade: "Medicina Paliativa",
        estabelecimentos: [
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ]
    },
    {
        especialidade: "Nefrologia",
        estabelecimentos: [
            {
                nome: "Med.Álise Centro de Hemodialise",
                endereco: "Rua Treze de Maio, 175, Centro, São Vicente / SP - CEP 11310-330",
                telefones: ["(13) 3467-8179", "(13) 99700-8179"],
            },
        ],
    },
    {
        especialidade: "Neurologia",
        estabelecimentos: [
            {
                nome: "Clínica Médica São José",
                endereco: "Rua Tibiriçá, 439, Centro, São Vicente / SP - CEP 11320-020",
                telefones: ["(13) 3569-0200"],
            },
        ],
    },
    {
        especialidade: "Nutrição",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - São Vicente",
                endereco: "Avenida Presidente Wilson, 199, Itararé, São Vicente / SP - CEP 11320-001",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "Nutrologia",
        estabelecimentos: [
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ]
    },
    {
        especialidade: "Odontopediatria",
        estabelecimentos: [
            {
                nome: "Cintia Mara Aoki Tronoloni",
                endereco: "Rua Frei Gaspar, 931, Centro, São Vicente / SP - CEP 11310-061",
                telefones: ["(13) 99743-1234"],
            },
            {
                nome: "Dentiste Odontologia",
                endereco: "Rua Amador Bueno da Ribeira, 64, Centro, São Vicente / SP - CEP 11320-060",
                telefones: ["(13) 98166-0058"],
            },
            {
                nome: "Marcela Fernanda Bianco Mastros",
                endereco: "Rua Dom Lara, 850, Vila Valença, São Vicente / SP - CEP 11390-140",
                telefones: ["(13) 3329-8355", "(13) 99114-2596"],
            },
            {
                nome: "Sorrir Mais Clínica Odontológica São Vicente",
                endereco: "Rua João Ramalho, 803, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3467-8111", "(13) 3468-6988", "(11) 99112-1315"],
            },
        ],
    },
    {
        especialidade: "Oftalmologia",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "Ortodontia",
        estabelecimentos: [
            {
                nome: "Dentiste Odontologia",
                endereco: "Rua Amador Bueno da Ribeira, 64, Centro, São Vicente / SP - CEP 11320-060",
                telefones: ["(13) 98166-0058"],
            },
            {
                nome: "Juliana Perroni Oliva",
                endereco: "Rua João Ramalho, 626, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 99131-9870"],
            },
            {
                nome: "Marcela Fernanda Bianco Mastros",
                endereco: "Rua Dom Lara, 850, Vila Valença, São Vicente / SP - CEP 11390-140",
                telefones: ["(13) 3329-8355", "(13) 99114-2596"],
            },
        ],
    },
    {
        especialidade: "Ortopedia e Traumatologia",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - São Vicente",
                endereco: "Avenida Presidente Wilson, 199, Itararé, São Vicente / SP - CEP 11320-001",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Infantil",
        estabelecimentos: [
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Joelho",
        estabelecimentos: [
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Mão e Punho",
        estabelecimentos: [
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Ombro e Cotovelo",
        estabelecimentos: [
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Quadril",
        estabelecimentos: [
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Otorrinolaringologia",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "Pediatria",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - São Vicente",
                endereco: "Avenida Presidente Wilson, 199, Itararé, São Vicente / SP - CEP 11320-001",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ],
    },
    {
        especialidade: "Periodontia",
        estabelecimentos: [
            {
                nome: "Lourdes Regina Salgueiro Castro",
                endereco: "Avenida Marechal Deodoro, 737, Vila Valença, São Vicente / SP - CEP 11390-100",
                telefones: ["(13) 99719-2984"],
            },
            {
                nome: "Sorrir Mais Clínica Odontológica São Vicente",
                endereco: "Rua João Ramalho, 803, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3467-8111", "(13) 3468-6988", "(11) 99112-1315"],
            },
        ],
    },
    {
        especialidade: "Prótese Dentária",
        estabelecimentos: [
            {
                nome: "Lourdes Regina Salgueiro Castro",
                endereco: "Avenida Marechal Deodoro, 737, Vila Valença, São Vicente / SP - CEP 11390-100",
                telefones: ["(13) 99719-2984"],
            },
            {
                nome: "Sorrir Mais Clínica Odontológica São Vicente",
                endereco: "Rua João Ramalho, 803, Centro, São Vicente / SP - CEP 11310-050",
                telefones: ["(13) 3467-8111", "(13) 3468-6988", "(11) 99112-1315"],
            },
        ],
    },
    {
        especialidade: "Psicologia",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "Psiquiatria",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "Radiologia e Diagnóstico por Imagem",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - São Vicente",
                endereco: "Avenida Presidente Wilson, 199, Itararé, São Vicente / SP - CEP 11320-001",
                telefones: ["(13) 4090-1680"],
            },
        ],
    },
    {
        especialidade: "Reumatologia",
        estabelecimentos: [
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "RX",
        estabelecimentos: [
            {
                nome: "Instituto Ortopédico Itararé",
                endereco: "Avenida Presidente Wilson, 1340, Centro, São Vicente / SP - CEP 11320-000",
                telefones: ["(13) 3468-1550", "(13) 3468-2182"],
            },
        ],
    },
    {
        especialidade: "Urologia",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - São Vicente",
                endereco: "Avenida Presidente Wilson, 199, Itararé, São Vicente / SP - CEP 11320-001",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico de São Vicente",
                endereco: "Avenida Deputado Ulisses Guimarães, 1443, Jardim Rio Branco, São Vicente / SP - CEP 11347-000",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Home Saúde & Vida LTDA",
                endereco: "Rua Frei Gaspar, 739, Centro, São Vicente / SP - CEP 11310-060",
                telefones: ["(13) 3466-1851"],
            },
        ],
    }
];

export default RedesSaoVicente;