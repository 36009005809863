import './Mission.css';

import eyeicon from "../../../../assets/img-main/eye.webp"
import targeticon from "../../../../assets/img-main/target.webp"
import valueicon from "../../../../assets/img-main/value.webp"

const Mission = () => {
    return (
        <div className="valores__info">
            <ul className="valores__info-list">

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img 
                            src={targeticon}
                            alt="ícone de alvo/foco/missão"
                        />
                        <h4>
                            Nossa Missão
                        </h4>
                    </div>
                    <p>
                        Cuidar da saúde das pessoas de maneira completa, adequada e acolhedora por meio de uma gestão inteligente e sustentável, gerando valor para todos os envolvidos.
                    </p>
                </li>

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img 
                            src={valueicon}
                            alt="ícone de valores da empresa"
                        />
                        <h4>
                            Nossos Valores
                        </h4>
                    </div>
                    <p>
                        Fé, acolhimento, comprometimento, inovação, melhoria contínua, otimismo, respeito, sustentabilidade e transparência.
                    </p>
                </li>

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img 
                            src={eyeicon}
                            alt="Ícone de visão da empresa"
                        />
                        <h4>
                            Nossa Visão
                        </h4>
                    </div>
                    <p>
                        Ser uma instituição de crescimento sustentável, assegurando excelência na prestação de serviços de saúde e credibilidade para as partes interessadas.
                    </p>
                </li>

            </ul>

        </div>
    )
}

export default Mission;