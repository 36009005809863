import "./Cobertura.css";
import hospitalicon from "../../../../assets/img-main/hospital.webp"

const Cobertura = () => {
    return (
        <div className="cobertura__container">

            <div className="cobertura__titulo">
                <h2>
                    HOSPITAIS CREDENCIADOS
                </h2>
            </div>



            <div className="rede__container">

                <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Guarujá
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Hospital GoCare
                        </p>
                        <p>
                            Rua Montenegro, 41<br />
                            Vila Maia
                        </p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Guarujá
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Hospital Guarujá
                        </p>
                        <p>
                            Av. Santos Dumont, 3651<br />
                            Sítio Paecara
                        </p>
                    </div>
                </div>

                {/* <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Guarujá
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Hospital Casa de Saúde
                        </p>
                        <p>
                            Av. Dep. Emílio Carlos, 109<br />
                            Vila Maia
                        </p>
                    </div>
                </div> */}

                {/* <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Praia Grande
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Hospital Casa de Saúde
                        </p>
                        <p>
                            Av. Gilberto Fouad Beck, 150<br />
                            Nova Mirim
                        </p>
                    </div>
                </div> */}
                {/* 
                <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Santos
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Hospital Casa de Saúde
                        </p>
                        <p>
                            Rua Dr. Armando Salles de Oliveira, 131<br />
                            Boqueirão
                        </p>
                    </div>
                </div> */}

                <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Santos
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Santa Casa de Santos
                        </p>
                        <p>
                            Av. Dr. Cláudio Luiz da Costa, 50<br />
                            Jabaquara
                        </p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Santos
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            APAS Santo Expedito
                        </p>
                        <p>
                            R. Carvalho de Mendonça, 335<br />
                            Vila Belmiro
                        </p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Santos
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Frei Galvão
                        </p>
                        <p>
                            Rua Dr. Heitor de Moraes, 19<br />
                            Boqueirão
                        </p>
                    </div>
                </div>

                <div className="redes">
                    <div className="redes__info">
                        <img 
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            São Vicente
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Frei Galvão
                        </p>
                        <p>
                            Av. Presidente Wilson, 199<br />
                            Itararé
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Cobertura;