
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Benefits.css';

import benefit1 from '../../../../assets/img-main/benefits/benefits1.webp';
import benefit2 from '../../../../assets/img-main/benefits/benefits2.webp';
import benefit3 from '../../../../assets/img-main/benefits/benefits3.webp';
import benefit4 from '../../../../assets/img-main/benefits/benefits4.webp';
import benefit5 from '../../../../assets/img-main/benefits/benefits5.webp';
import benefit6 from '../../../../assets/img-main/benefits/benefits6.webp';
import benefit7 from '../../../../assets/img-main/benefits/benefits7.webp';
import benefit8 from '../../../../assets/img-main/benefits/benefits8.webp';
import benefit9 from '../../../../assets/img-main/benefits/benefits9.webp';
import benefit10 from '../../../../assets/img-main/benefits/benefits10.webp';


const BenefitsCarousel = () => {
    const benefits = [
        benefit1,
        benefit2,
        benefit3,
        benefit4,
        benefit5,
        benefit6,
        benefit7,
        benefit8,
        benefit9,
        benefit10
    ];

    return (
        <section className="benefits">

            <Swiper
                modules={[Autoplay, Navigation]}
                spaceBetween={0}
                slidesPerView={2}
                loop={true}
                autoplay={{ delay: 2000 }}
                breakpoints={{
                    360: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1025: {
                        slidesPerView: 4,
                    },
                    1440: {
                        slidesPerView: 5,
                    }
                }}
                navigation={true}
                className="custom-swiper"
            >
                {benefits.map((benefit, index) => (
                    <SwiperSlide key={index}>
                        <img 
                            src={benefit}
                            alt={`Slide Benefícios ${index + 1}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="benefits__title">
                <h1>Por que ter um<br /><span>Plano de Saúde<br /> GoCare?</span></h1>
                <p className='benefits__conclusao'>
                    Contratar um plano de saúde é uma decisão essencial para garantir a qualidade de vida e a segurança de sua família. A GoCare se destaca por oferecer uma gama de vantagens que tornam essa escolha ainda mais atrativa. Optar por um plano de saúde significa investir no bem-estar e na tranquilidade de todos. Aqui estão alguns motivos para escolher a GoCare:
                </p>
            </div>



            <div className="benefits___container">
                <div className="benefits__content cobertura-nacional">
                    <div></div>
                    <div className="benefits-text">
                        <h3>COBERTURA NACIONAL (URGÊNCIA E EMERGÊNCIA)</h3>
                        <p>
                            Na GoCare, você tem cobertura nacional para urgências e emergências através da rede ABRAMGE. Isso significa que, esteja onde estiver no Brasil, garantimos acesso a atendimento médico de qualidade para você e sua família, assegurando tranquilidade em situações críticas.
                        </p>
                    </div>
                </div>

                <div className="benefits__content preco-justo">
                    <div className="benefits-text">
                        <h3>PREÇO JUSTO</h3>
                        <p>
                            Outra preocupação ao contratar um plano de saúde é o custo. A GoCare se destaca por oferecer um preço justo, que cabe no seu bolso, sem comprometer a qualidade do atendimento. A acessibilidade dos valores permite que mais pessoas possam se beneficiar de um serviço de saúde de qualidade, garantindo tranquilidade em momentos de necessidade.
                        </p>
                    </div>
                    <div></div>
                </div>

                <div className="benefits__content telemedicina">
                    <div></div>
                    <div className="benefits-text">
                        <h3>TELEMEDICINA 24 HORAS</h3>
                        <p>
                            A modernidade e a conveniência também são pontos fortes da GoCare. Com a telemedicina disponível 24 horas por dia, você pode consultar médicos e obter orientações sem sair de casa. Essa facilidade é ideal para situações em que não é possível se deslocar até um hospital ou clínica, além de economizar tempo e proporcionar mais conforto para você e sua família.
                        </p>
                    </div>
                </div>

                <div className="benefits__content ampla-rede">
                    <div className="benefits-text">
                        <h3>AMPLA REDE CREDENCIADA</h3>
                        <p>
                            A ampla rede credenciada é outro destaque da GoCare. Com uma vasta gama de hospitais, clínicas e profissionais de saúde disponíveis, você tem a certeza de encontrar atendimento de qualidade onde quer que esteja. Isso amplia suas opções de escolha e garante que você possa contar com o melhor cuidado possível, sempre que precisar.
                        </p>
                    </div>
                    <div></div>
                </div>

            </div>
            <p className='benefits__conclusao'>
                Contratar a GoCare é uma decisão inteligente para quem busca um plano de saúde completo, acessível e prático. Com cobertura nacional para urgências e emergências, preço justo, telemedicina 24 horas, e uma ampla rede credenciada, oferecemos uma excelente relação custo-benefício. Garanta a segurança e o bem-estar de sua família com a GoCare e desfrute de todos esses benefícios sem comprometer o seu orçamento.
            </p>
            <h3><span>SUA SAÚDE</span> É UM PLANO DE AGORA!</h3>
        </section>
    );
};

export default BenefitsCarousel;
